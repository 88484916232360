import noop from 'lodash/noop'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from 'react'

import { Task } from '@/gql/generated/graphql'

type Action = { payload: string; type: 'SET_TITLE' } | { type: 'CLEAR' }

const taskReducer = (state: Partial<Task>, action: Action): Partial<Task> => {
  switch (action.type) {
    case 'SET_TITLE': {
      return { ...state, title: action.payload }
    }
    case 'CLEAR': {
      return {}
    }
  }
}

const NewTaskContext = createContext<{
  dispatch: Dispatch<Action>
  task: Partial<Task>
}>({
  dispatch: noop,
  task: {},
})
NewTaskContext.displayName = 'NewTaskContext'

export const useNewTaskContext = () => useContext(NewTaskContext)

export const NewTaskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [task, dispatch] = useReducer(taskReducer, {})

  return (
    <NewTaskContext.Provider value={{ dispatch, task }}>
      {children}
    </NewTaskContext.Provider>
  )
}
NewTaskProvider.displayName = 'NewTaskProvider'
