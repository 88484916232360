import { Link } from '@tanstack/react-router'
import { ComponentProps, FC, PropsWithChildren } from 'react'

import styles from '@/components/layout/navigation/NavigationItem.module.css'

interface Properties extends PropsWithChildren<ComponentProps<typeof Link>> {}

export const NavigationItem: FC<Properties> = ({
  children,
  to,
  ...properties
}) => {
  return (
    <div className="relative h-11 w-13.5">
      <Link className={styles.NavItem} to={to} {...properties}>
        <span>{children}</span>
        <span className={styles.ActiveIndicator} />
      </Link>
    </div>
  )
}
NavigationItem.displayName = 'NavigationItem'
