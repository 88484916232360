import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  configSchemaEnvironmentKeys,
  configurationVariablesSchemaValidator,
  ConfigurationVariables,
} from '@/lib/configurationVariablesSchemaValidator'
import { getApiHost } from '@/lib/getApiHost'
import { logger } from '@/lib/logger'
import { makeErrorFromUnknown } from '@/lib/utils'

interface ConfigurationVariablesContextState extends ConfigurationVariables {}

const ConfigurationVariablesContext = createContext<
  ConfigurationVariablesContextState | undefined
>(undefined)
ConfigurationVariablesContext.displayName = 'ConfigurationVariablesContext'

export const useConfigurationVariables = () =>
  useContext(ConfigurationVariablesContext)

const retrieveConfigurationVariables = async () => {
  const localVariables: Partial<ConfigurationVariables['env']> = {}
  for (const variable of configSchemaEnvironmentKeys) {
    const localVariable = import.meta.env[variable]
    if (localVariable) {
      localVariables[variable] = localVariable
    }
  }

  const validatedLocalVariables =
    configurationVariablesSchemaValidator.safeParse({
      env: localVariables,
    })

  if (validatedLocalVariables.success) {
    return validatedLocalVariables.data
  }

  try {
    const result = await fetch(`${getApiHost()}/api/v1/config/`)
    const jsonResult = await result.json()

    const { env } = configurationVariablesSchemaValidator.parse(jsonResult)

    return { env: { ...env, ...localVariables } }
  } catch (maybeError) {
    logger.error(makeErrorFromUnknown(maybeError))
  }
}

export const ConfigurationVariablesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [data, setData] = useState<ConfigurationVariablesContextState>()

  useEffect(() => {
    const fetchConfig = async () => {
      const resp = await retrieveConfigurationVariables()
      setData(resp)
    }

    void fetchConfig()
  }, [])

  return (
    <ConfigurationVariablesContext.Provider value={data}>
      {children}
    </ConfigurationVariablesContext.Provider>
  )
}
ConfigurationVariablesProvider.displayName = 'ConfigurationVariablesProvider'
