import { Outlet, createFileRoute } from '@tanstack/react-router'

import { TaskCompletedIcon } from '@/components/icons/TaskCompletedIcon'

export const Route = createFileRoute('/_private/all-work')({
  component: Outlet,
})

Route.interloom = {
  NavigationIcon: TaskCompletedIcon,
}
