import { Link, LinkProps } from '@tanstack/react-router'
import { useCallback, useMemo } from 'react'

import { ForwardSlash } from '@/components/icons/ForwardSlash'
import { ThreeDotsVerticalIcon } from '@/components/icons/ThreeDotsVerticalIcon'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utils'

interface Properties extends LinkProps {
  Icon: IconComponent
  textClassName?: string
  title: string
  className?: string
}

const activeProperties = { className: 'pointer-events-none' }
const Title = ({
  Icon,
  className,
  textClassName,
  title,
  ...properties
}: Properties) => {
  return (
    <Link
      {...properties}
      className={cn(
        'flex flex-row items-center gap-0.5 rounded-sm p-0.5 text-xs-regular opacity-50 hover:opacity-100',
        className
      )}
      title={title}
      activeProps={activeProperties}
    >
      <Icon className="size-4 min-w-4" />
      <span className={cn('w-full max-w-80 grow truncate', textClassName)}>
        {title}
      </span>
    </Link>
  )
}
Title.displayName = 'Title'

interface BreadcrumbProperties {
  task: FetchTasksByIdQuery['tasks'][number]
}

const BreadcrumbComponent = ({ task }: BreadcrumbProperties) => {
  const ParentTaskIcon = useCallback(
    () => <TaskIcon completed={!!task.parentTask?.completedAt} size="sm" />,
    [task.parentTask]
  )

  const CurrentTaskIcon = useCallback(
    () => <TaskIcon completed={!!task.completedAt} size="sm" />,
    [task.completedAt]
  )

  const parentTaskParameters = useMemo(
    () => ({ taskId: task.parentTask?.id }),
    [task.parentTask?.id]
  )
  const currentTaskParameters = useMemo(() => ({ taskId: task.id }), [task.id])

  return (
    <>
      {!!task.parentTask?.parentTask && (
        <>
          <ThreeDotsVerticalIcon
            className={cn('rotate-90 opacity-50')}
            data-testid="granparent-breadcrumb"
          />
          <ForwardSlash
            className="size-4 opacity-[0.18]"
            data-testid="granparent-breadcrumb-slash"
          />
        </>
      )}

      {!!task.parentTask && (
        <>
          <Title
            Icon={ParentTaskIcon}
            title={task.parentTask.title}
            data-testid="parent-task-breadcrumb"
            to="/all-work/$taskId"
            params={parentTaskParameters}
          />

          <ForwardSlash className="size-4 opacity-[0.18]" />
        </>
      )}

      <Title
        Icon={CurrentTaskIcon}
        title={task.title}
        data-testid="task-breadcrumb"
        to="/all-work/$taskId"
        params={currentTaskParameters}
      />
    </>
  )
}

export const TaskBreadcrumb = Object.assign(BreadcrumbComponent, {
  Title,
})

BreadcrumbComponent.displayName = 'TaskBreadcrumb'
