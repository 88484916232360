import { GraphQLClient } from 'graphql-request'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { graphql } from '@/gql/generated'
import {
  ApplyTaskRefinementMutation,
  CreateMessageInput,
  CreateMessageMutation,
  CreateTaskInput,
  CreateTaskMutation,
  DeleteTaskMutation,
  FetchAllTasksQuery,
  FetchAllUsersQuery,
  FetchTasksByIdQuery,
  OrderSubtasksMutation,
  QuerySimilarTasksArgs,
  SetTaskStatusInput,
  SimilarTasksQuery,
  TaskMessagesQuery,
  UpdateTaskInput,
  RefineTaskMutation,
  SetTaskStatusMutation,
  UpdateTaskMutation,
  FetchViewerQuery,
  RefineTaskInput,
} from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'

export interface Api {
  applyTaskRefinement: (
    refinementId: string
  ) => Promise<ApplyTaskRefinementMutation>
  createMessage: (
    input: CreateMessageInput
  ) => Promise<CreateMessageMutation['createMessage']>
  createTask: (
    taskInput: CreateTaskInput
  ) => Promise<CreateTaskMutation['createTask']>
  deleteTask: (taskId: string) => Promise<DeleteTaskMutation>
  fetchAllUsers: () => Promise<FetchAllUsersQuery>
  fetchAllWork: () => Promise<FetchAllTasksQuery['tasks']>
  fetchSimilarTasks: (
    input: QuerySimilarTasksArgs
  ) => Promise<SimilarTasksQuery>
  fetchTaskMessages: (taskId: string) => Promise<TaskMessagesQuery>
  fetchTasksById: (taskIds: string[]) => Promise<FetchTasksByIdQuery['tasks']>
  graphQLClient: GraphQLClient
  orderSubtasks: (
    orderedSubtaskIds: string[],
    insertAfterSubtaskWithId?: string
  ) => Promise<OrderSubtasksMutation>
  refineTask: (input: RefineTaskInput) => Promise<RefineTaskMutation>
  setTaskStatus: (input: SetTaskStatusInput) => Promise<SetTaskStatusMutation>
  updateTask: (input: UpdateTaskInput) => Promise<UpdateTaskMutation>
  fetchViewer: () => Promise<FetchViewerQuery>
}

const ApiContext = createContext<Api | undefined>(undefined)
ApiContext.displayName = 'ApiContext'

export const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('useApi must be used within a ApiProvider')
  return context
}

export const createTaskQueryKey = (taskId: string) => ['task', taskId]

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getUser } = useOIDCAuth()

  const graphQLClient = useMemo(
    () =>
      new GraphQLClient(`${getApiHost()}/api/v1/graphql`, {
        fetch: async (url, options) => {
          const user = await getUser()
          if (!user) {
            return await fetch(url, options)
          }

          const headers = {
            ...options?.headers,
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          }

          return await fetch(url, { ...options, headers })
        },
      }),
    [getUser]
  )

  const applyTaskRefinement: Api['applyTaskRefinement'] = useCallback(
    async (refinementId) => {
      return await graphQLClient.request(
        graphql(`
          mutation applyTaskRefinement($refinementId: ID!) {
            applyTaskRefinement(input: { id: $refinementId }) {
              id
            }
          }
        `),
        {
          refinementId,
        }
      )
    },
    [graphQLClient]
  )

  const createMessage: Api['createMessage'] = useCallback(
    async (input) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateMessage($input: CreateMessageInput!) {
            createMessage(input: $input) {
              id
              createdAt
              threadId
              author {
                id
                name
              }
              body {
                text
              }
            }
          }
        `),
        {
          input,
        }
      )

      return response.createMessage
    },
    [graphQLClient]
  )

  const createTask: Api['createTask'] = useCallback(
    async (taskInput) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateTask($taskInput: CreateTaskInput!) {
            createTask(input: $taskInput) {
              id
              title
              createdAt
            }
          }
        `),
        {
          taskInput,
        }
      )

      return response.createTask
    },
    [graphQLClient]
  )

  const deleteTask: Api['deleteTask'] = useCallback(
    async (taskId: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteTask($taskId: ID!) {
            deleteTask(id: $taskId)
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchAllUsers: Api['fetchAllUsers'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchAllUsers {
          users {
            id
            name
            avatarUrl
          }
        }
      `),
      {}
    )
  }, [graphQLClient])

  const fetchAllWork: Api['fetchAllWork'] = useCallback(async () => {
    const response = await graphQLClient.request(
      graphql(`
        query FetchAllTasks {
          tasks {
            id
            title
            createdAt
            completedAt
            assignee {
              id
              name
              avatarUrl
            }
          }
        }
      `)
    )
    return response.tasks
  }, [graphQLClient])

  const fetchSimilarTasks: Api['fetchSimilarTasks'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          query SimilarTasks($taskId: ID!) {
            similarTasks(taskId: $taskId) {
              score
              task {
                id
                title
                completedAt
              }
            }
          }
        `),
        {
          taskId: input.taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchTaskMessages: Api['fetchTaskMessages'] = useCallback(
    async (taskId) => {
      return await graphQLClient.request(
        graphql(`
          query TaskMessages($taskId: ID!) {
            tasks(ids: [$taskId]) {
              thread {
                id
                messages {
                  id
                  createdAt
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                  type
                  task {
                    title
                    description
                  }
                }
              }
            }
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchTasksById: Api['fetchTasksById'] = useCallback(
    async (taskIds) => {
      const response = await graphQLClient.request(
        graphql(`
          query FetchTasksById($taskIds: [ID!]!) {
            tasks(ids: $taskIds) {
              id
              title
              createdAt
              completedAt
              description
              assigneeId
              thread {
                id
                messages {
                  id
                  createdAt
                  threadId
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                }
              }
              subtasks {
                id
                title
                createdAt
                completedAt
                assignee {
                  id
                  name
                  avatarUrl
                }
              }
              parentTask {
                id
                title
                completedAt
                parentTask {
                  id
                }
              }
            }
          }
        `),
        {
          taskIds,
        }
      )

      return response.tasks
    },
    [graphQLClient]
  )

  const orderSubtasks: Api['orderSubtasks'] = useCallback(
    async (orderedSubtaskIds, insertAfterSubtaskWithId) => {
      return await graphQLClient.request(
        graphql(`
          mutation OrderSubtasks(
            $orderedSubtaskIds: [ID!]!
            $insertAfterSubtaskWithId: ID
          ) {
            orderSubtasks(
              input: {
                orderedSubtaskIds: $orderedSubtaskIds
                insertAfterSubtaskWithId: $insertAfterSubtaskWithId
              }
            ) {
              subtasks {
                id
              }
            }
          }
        `),
        {
          ...(insertAfterSubtaskWithId && { insertAfterSubtaskWithId }),
          orderedSubtaskIds,
        }
      )
    },
    [graphQLClient]
  )

  const refineTask: Api['refineTask'] = useCallback(
    async ({ precedentTaskIds, previousTaskRefinementFeedback, taskId }) => {
      return await graphQLClient.request(
        graphql(`
          mutation refineTask(
            $taskId: ID!
            $precedentTaskIds: [ID!]
            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput
          ) {
            refineTask(
              input: {
                taskId: $taskId
                precedentTaskIds: $precedentTaskIds
                previousTaskRefinementFeedback: $previousTaskRefinementFeedback
              }
            ) {
              id
              updates {
                change {
                  parentTaskId
                  title
                }
              }
              precedentTasks {
                assignee {
                  name
                  avatarUrl
                }
                id
                title
                completedAt
                createdAt
              }
            }
          }
        `),
        {
          precedentTaskIds,
          previousTaskRefinementFeedback,
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const setTaskStatus: Api['setTaskStatus'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {
            setTaskStatus(input: { id: $id, status: $status }) {
              id
              completedAt
            }
          }
        `),
        {
          id: input.id,
          status: input.status,
        }
      )
    },
    [graphQLClient]
  )

  const updateTask: Api['updateTask'] = useCallback(
    async (input: UpdateTaskInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateTask(
            $id: ID!
            $title: String
            $description: String
            $assigneeId: ID
          ) {
            updateTask(
              input: {
                id: $id
                title: $title
                description: $description
                assigneeId: $assigneeId
              }
            ) {
              id
              title
              description
              assigneeId
            }
          }
        `),
        {
          assigneeId: input.assigneeId,
          description: input.description,
          id: input.id,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const fetchViewer: Api['fetchViewer'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchViewer {
          viewer {
            id
            name
            avatarUrl
            tasks {
              id
              title
              createdAt
              assigneeId
              parentTask {
                id
              }
            }
          }
        }
      `)
    )
  }, [graphQLClient])

  return (
    <ApiContext.Provider
      value={{
        applyTaskRefinement,
        createMessage,
        createTask,
        deleteTask,
        fetchAllUsers,
        fetchAllWork,
        fetchSimilarTasks,
        fetchTaskMessages,
        fetchTasksById,
        fetchViewer,
        graphQLClient,
        orderSubtasks,
        refineTask,
        setTaskStatus,
        updateTask,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
ApiProvider.displayName = 'ApiProvider'
