import { QueryClient } from '@tanstack/react-query'
import { createRouter, ParseRoute, RouteContext } from '@tanstack/react-router'
import { ComponentType, HTMLAttributes } from 'react'
import { UnleashClient } from 'unleash-proxy-client'

import { Api } from '@/contexts/ApiProvider'
import { OidcAuthContextState } from '@/contexts/OidcAuthContext'
import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { IconComponent } from '@/lib/types'
import { routeTree } from '@/routeTree.gen'

// @ts-expect-error - Context is added in the Entry.tsx file
const temporaryContext: RouteContext = {}
export const router = createRouter({ context: temporaryContext, routeTree })
export type ValidRoute = ParseRoute<typeof routeTree>['fullPath']

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  interface RouteContext {
    queryClient: QueryClient
    api: Api
    getUser: OidcAuthContextState['getUser']
    configurationVariables: ConfigurationVariables
    unleashClient: UnleashClient
  }

  interface Route {
    interloom?: {
      Breadcrumb?: ComponentType<HTMLAttributes<HTMLSpanElement>>
      NavigationIcon?: IconComponent
    }
  }
}
