import { IconComponent } from '@/lib/types'

export const CheckedCircleSolidIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12 2C6.47917 2 2 6.47917 2 12C2 17.5208 6.47917 22 12 22C17.5208 22 22 17.5208 22 12C22 6.47917 17.5208 2 12 2ZM10 17.4115L5.29167 12.7083L6.70833 11.2917L10 14.5885L17.2917 7.29167L18.7083 8.70833L10 17.4115Z"
        fill="currentColor"
        id="Vector"
      />
    </svg>
  )
}

CheckedCircleSolidIcon.displayName = 'CheckedCircleSolidIcon'
