import { IconComponent } from '@/lib/types'

export const HomeHouseStartIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M11.9766 1.99984C11.8151 2.00505 11.6615 2.06234 11.5365 2.1613L4.42969 7.76025C3.52865 8.47119 3 9.55713 3 10.7056V20.2498C3 20.9321 3.56771 21.4998 4.25 21.4998H9.25C9.93229 21.4998 10.5 20.9321 10.5 20.2498V15.2498C10.5 15.1014 10.6016 14.9998 10.75 14.9998H13.25C13.3984 14.9998 13.5 15.1014 13.5 15.2498V20.2498C13.5 20.9321 14.0677 21.4998 14.75 21.4998H19.75C20.4323 21.4998 21 20.9321 21 20.2498V10.7056C21 9.55713 20.4714 8.47119 19.5703 7.76025L12.4635 2.1613C12.3255 2.05192 12.151 1.99463 11.9766 1.99984ZM12 3.70557L18.6432 8.93734C19.1849 9.36442 19.5 10.0155 19.5 10.7056V19.9998H15V15.2498C15 14.2915 14.2083 13.4998 13.25 13.4998H10.75C9.79167 13.4998 9 14.2915 9 15.2498V19.9998H4.5V10.7056C4.5 10.0155 4.8151 9.36442 5.35677 8.93734L12 3.70557Z"
        fill="currentColor"
      />
    </svg>
  )
}

HomeHouseStartIcon.displayName = 'HomeHouseStartIcon'
