import { BrowserAgent } from '@newrelic/browser-agent'
import { JSErrors } from '@newrelic/browser-agent/features/jserrors'

const init = () => {
  if (!import.meta.env.PROD) {
    return
  }

  const beaconProxy = window.location.host + '/api/v1/newrelic/beacon'
  const licenseKey = 'NRJS-1bada4931f35612a940'
  const applicationID = '538603898'

  const options = {
    features: [JSErrors],
    info: {
      applicationID,
      beacon: 'bam.eu01.nr-data.net',
      errorBeacon: 'bam.eu01.nr-data.net',
      licenseKey,
      sa: 1,
    },
    init: {
      ajax: {
        deny_list: [
          'bam.eu01.nr-data.net',
          // ignore all requests to the beacon proxy
          ...['jserrors', 'events', 'ins', 'resources'].map(
            (t) => `${beaconProxy}/${t}/1/${licenseKey}`
          ),
          `${beaconProxy}/1/${licenseKey}`,
          `${beaconProxy}/browser/blobs`,
        ],
      },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      proxy: {
        beacon: beaconProxy,
      },
    },
    loader_config: {
      accountID: '4474536',
      agentID: applicationID,
      applicationID,
      licenseKey,
      trustKey: '4474536',
    },
  }

  const newrelic = new BrowserAgent(options)
  const applicationVersion = import.meta.env.PUBLIC_VERSION
  if (applicationVersion) {
    newrelic.setApplicationVersion(applicationVersion)
  }

  return newrelic
}

export const newrelic = init()
