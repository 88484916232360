import { createFileRoute, Navigate } from '@tanstack/react-router'
import { useEffect } from 'react'

import { useOIDCAuth } from '@/contexts/OidcAuthContext'

const AuthCallback = () => {
  const { error, loading, signInCallback } = useOIDCAuth()

  useEffect(() => {
    void signInCallback()
  }, [signInCallback])

  if (loading) return <div className="flex flex-1 items-center">Loading</div>

  if (error) return <Navigate to="/signin" />

  return <Navigate to="/" />
}

AuthCallback.displayName = 'AuthCallback'

export const Route = createFileRoute('/_auth/auth-callback')({
  component: AuthCallback,
})
