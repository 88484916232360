import { IconComponent } from '@/lib/types'

export const TaskTodoStackIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M6.25 3.00004C5.97917 2.99743 5.72917 3.13806 5.59115 3.37243C5.45573 3.60681 5.45573 3.89326 5.59115 4.12764C5.72917 4.36201 5.97917 4.50264 6.25 4.50004H17.75C18.0208 4.50264 18.2708 4.36201 18.4089 4.12764C18.5443 3.89326 18.5443 3.60681 18.4089 3.37243C18.2708 3.13806 18.0208 2.99743 17.75 3.00004H6.25ZM4.75 5.50004C4.47917 5.49743 4.22917 5.63806 4.09115 5.87243C3.95573 6.10681 3.95573 6.39327 4.09115 6.62764C4.22917 6.86202 4.47917 7.00264 4.75 7.00004H19.25C19.5208 7.00264 19.7708 6.86202 19.9089 6.62764C20.0443 6.39327 20.0443 6.10681 19.9089 5.87243C19.7708 5.63806 19.5208 5.49743 19.25 5.50004H4.75ZM4.25 8.00004C3.29167 8.00004 2.5 8.7917 2.5 9.75004V19.25C2.5 20.2084 3.29167 21 4.25 21H19.75C20.7083 21 21.5 20.2084 21.5 19.25V9.75004C21.5 8.7917 20.7083 8.00004 19.75 8.00004H4.25ZM4.25 9.50004H19.75C19.8984 9.50004 20 9.6016 20 9.75004V19.25C20 19.3985 19.8984 19.5 19.75 19.5H4.25C4.10156 19.5 4 19.3985 4 19.25V9.75004C4 9.6016 4.10156 9.50004 4.25 9.50004Z"
        fill="currentColor"
      />
      <path
        d="M15.2453 12.0003C15.0526 12.0081 14.8677 12.0888 14.7297 12.2268L11.7609 15.1982L10.2922 13.7268C10.1021 13.5315 9.82343 13.4534 9.56041 13.5211C9.29739 13.5888 9.09166 13.7945 9.02395 14.0576C8.95624 14.3206 9.03436 14.5992 9.22968 14.7893L11.2297 16.7893C11.5239 17.081 11.9979 17.081 12.2922 16.7893L15.7922 13.2893C16.0135 13.0732 16.0786 12.7425 15.9588 12.4586C15.8391 12.1748 15.5552 11.9925 15.2453 12.0003Z"
        fill="currentColor"
      />
    </svg>
  )
}

TaskTodoStackIcon.displayName = 'TaskTodoStackIcon'
