import { IconComponent } from '@/lib/types'

export const ThreeDotsVerticalIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M14 6C14 4.89583 13.1042 4 12 4C10.8958 4 10 4.89583 10 6C10 7.10417 10.8958 8 12 8C13.1042 8 14 7.10417 14 6ZM14 12C14 10.8958 13.1042 10 12 10C10.8958 10 10 10.8958 10 12C10 13.1042 10.8958 14 12 14C13.1042 14 14 13.1042 14 12ZM14 18C14 16.8958 13.1042 16 12 16C10.8958 16 10 16.8958 10 18C10 19.1042 10.8958 20 12 20C13.1042 20 14 19.1042 14 18Z"
        fill="currentColor"
      />
    </svg>
  )
}

ThreeDotsVerticalIcon.displayName = 'ThreeDotsVerticalIcon'
