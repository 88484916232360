import { FC } from 'react'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { cn } from '@/lib/utils'

export interface Properties {
  className?: string
  completed?: boolean
  size?: 'md' | 'lg' | 'sm'
}

export const TaskIcon: FC<Properties> = ({
  className,
  completed,
  size = 'lg',
}) => {
  const sizeClass = cn({
    'size-4 min-w-4 min-h-4': size === 'sm',
    'size-6 min-w-6 min-h-6': size === 'md',
    'size-8 min-w-8 min-h-8': size === 'lg',
  })
  return (
    <>
      {completed ? (
        <CheckedCircleSolidIcon className={cn(sizeClass, className)} />
      ) : (
        <TaskCircularCheckboxEmptyIcon className={cn(sizeClass, className)} />
      )}
    </>
  )
}
TaskIcon.displayName = 'TaskIcon'
