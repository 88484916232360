import { createFileRoute } from '@tanstack/react-router'
import { FC } from 'react'

import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { useNewTaskContext } from '@/contexts/NewTaskProvider'
import { TaskBreadcrumb } from '@/features/task/components/TaskBreadcrumb'
import { cn } from '@/lib/utils'

export interface AllWorkNewSearch {
  assignToMe?: boolean
}

export const Route = createFileRoute('/_private/all-work/new')({
  validateSearch: (search: Record<string, unknown>): AllWorkNewSearch => {
    return {
      assignToMe: search.assignToMe === true,
    }
  },
})

const TaskDetailBreadcrumb: FC = () => {
  const { task } = useNewTaskContext()

  const newTaskTitle = 'New Task'
  const hasTitle = !!task.title
  const title: string = (hasTitle ? task.title : newTaskTitle) ?? ''

  return (
    <TaskBreadcrumb.Title
      Icon={TaskCircularCheckboxEmptyIcon}
      className="min-w-28 border border-dashed border-film-stronger"
      textClassName={cn(hasTitle ? '' : 'opacity-20')}
      title={title}
    />
  )
}
TaskDetailBreadcrumb.displayName = 'TaskDetailBreadcrumb'

Route.interloom = {
  Breadcrumb: TaskDetailBreadcrumb,
}
