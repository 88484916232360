import Lottie, { LottieComponentProps } from 'lottie-react'
import { FC } from 'react'

import animationData from '@/components/ui/loading-spinner/animationData.json'
import { cn } from '@/lib/utils'

export const LoadingSpinner: FC<
  Omit<LottieComponentProps, 'animationData'>
> = ({ className, ...properties }) => {
  return (
    <Lottie
      animationData={animationData}
      className={cn('size-24', className)}
      loop
      {...properties}
    />
  )
}
LoadingSpinner.displayName = 'LoadingSpinner'
