/* v8 ignore next 54 */
import { FC, useMemo } from 'react'
import ReactDOM from 'react-dom/client'

import {
  ConfigurationVariablesProvider,
  useConfigurationVariables,
} from '@/contexts/ConfigurationVariablesProvider'
import { Entry } from '@/entry'

import '@/global.css'
import '@/newrelic.ts'
import 'react-toastify/dist/ReactToastify.css'

const rootElement = document.querySelector('#root')
if (!rootElement) {
  throw new Error('No root!')
}

const EntryWithConfig: FC = () => {
  const configurationVariables = useConfigurationVariables()

  const unleashProviderProperties = useMemo(
    () => ({
      config: {
        appName: 'webapp',
        clientKey: configurationVariables?.env.PUBLIC_UNLEASH_TOKEN ?? '',
        refreshInterval: 15,
        url: configurationVariables?.env.PUBLIC_UNLEASH_URL ?? '',
      },
    }),
    [
      configurationVariables?.env.PUBLIC_UNLEASH_TOKEN,
      configurationVariables?.env.PUBLIC_UNLEASH_URL,
    ]
  )

  if (!configurationVariables) {
    return
  }

  return (
    <Entry
      configurationVariables={configurationVariables}
      unleashProviderProperties={unleashProviderProperties}
    />
  )
}
EntryWithConfig.displayName = 'EntryWithConfig'

ReactDOM.createRoot(rootElement).render(
  <ConfigurationVariablesProvider>
    <EntryWithConfig />
  </ConfigurationVariablesProvider>
)
