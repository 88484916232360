import { createFileRoute, RouteContext } from '@tanstack/react-router'

import { TaskTodoStackIcon } from '@/components/icons/TaskTodoStackIcon'
import { Api } from '@/contexts/ApiProvider'

export interface AllWorkLoaderData {
  tasks: Awaited<ReturnType<Api['fetchAllWork']>>
}

export const makeFetchTasksQueryOptions = (context: RouteContext) => ({
  queryFn: async () => {
    return await context.api.fetchAllWork()
  },
  queryKey: ['tasks'],
})

export const Route = createFileRoute('/_private/all-work/')({
  loader: async ({ context }): Promise<AllWorkLoaderData> => ({
    tasks: await context.queryClient.ensureQueryData(
      makeFetchTasksQueryOptions(context)
    ),
  }),
})

Route.interloom = {
  NavigationIcon: TaskTodoStackIcon,
}
