import { IconComponent } from '@/lib/types'

export const HelpIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <g>
        <path
          fill="#292524"
          d="M12 2C6.487 2 2 6.484 2 12c0 1.635.432 3.167 1.13 4.531l-1.083 3.883c-.253.901.638 1.792 1.542 1.54L7.47 20.87C8.836 21.568 10.365 22 12 22c5.513 0 10-4.487 10-10 0-5.516-4.487-10-10-10zm0 1.5c4.703 0 8.5 3.797 8.5 8.5 0 4.703-3.797 8.5-8.5 8.5-1.5 0-2.904-.39-4.128-1.073a.758.758 0 00-.567-.068l-3.693 1.032 1.031-3.693a.758.758 0 00-.067-.568A8.426 8.426 0 013.5 12c0-4.703 3.797-8.5 8.5-8.5zm0 3a3.01 3.01 0 00-3 3c-.003.27.138.52.372.659.235.135.521.135.756 0A.754.754 0 0010.5 9.5c0-.836.664-1.5 1.5-1.5s1.5.664 1.5 1.5c0 .794-.323 1.026-.888 1.555-.563.528-1.362 1.349-1.362 2.695-.003.27.138.52.372.659.235.135.521.135.756 0a.754.754 0 00.372-.659c0-.83.325-1.075.888-1.599C14.198 11.628 15 10.826 15 9.5c0-1.648-1.352-3-3-3zm0 9a1 1 0 100 2 1 1 0 000-2z"
        ></path>
      </g>
    </svg>
  )
}

HelpIcon.displayName = 'HelpIcon'
