import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'

export const ErrorFallback: FC<{ error: Error }> = ({ error }) => {
  useEffect(() => {
    if (!error) return
    toast.error('Something went wrong!', {
      toastId: 'Something went wrong!',
    })
  }, [error])

  return <div>Something went wrong!</div>
}
ErrorFallback.displayName = 'ErrorFallback'
