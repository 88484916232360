import { FC } from 'react'

export const NavigationHorizontalDash: FC = () => {
  return (
    <div className="flex h-6 min-h-6 w-full min-w-5 items-center justify-center">
      <hr className="h-0.5 w-5 bg-film-normal" />
    </div>
  )
}

NavigationHorizontalDash.displayName = 'NavigationHorizontalDash'
