import { useRouter } from '@tanstack/react-router'
import { FC } from 'react'

import { FeedbackButton } from '@/components/layout/navigation/FeedbackButton'
import { NavigationHorizontalDash } from '@/components/layout/navigation/NavigationHorizontalDash'
import { NavigationItem } from '@/components/layout/navigation/NavigationItem'
import { ValidRoute } from '@/router'

export type NavigationOrder = Array<ValidRoute | '---'>

const NAVIGATION_ORDER: NavigationOrder = [
  '/',
  '/all-work',
  '---', // This inserts a horizontal dash <NavigationHorizontalDash />
] as const

export const Navigation: FC = () => {
  const router = useRouter()

  return (
    <nav className="flex min-h-full w-25 min-w-25 flex-col items-center justify-between py-4">
      <div className="flex flex-col items-center gap-2">
        {NAVIGATION_ORDER.map((path, index) => {
          if (path === '---') {
            return <NavigationHorizontalDash key={`dash-${index}`} />
          }

          const route = router.routesByPath[path]
          const NavigationIcon = route.interloom?.NavigationIcon
          if (!NavigationIcon) {
            throw new Error(
              `NavigationIcon not defined for the route "${path}"!`
            )
          }

          return (
            <NavigationItem
              data-testid={`NavigationItem-${path}`}
              key={route.id}
              to={path}
            >
              <NavigationIcon className="size-6" />
            </NavigationItem>
          )
        })}
      </div>
      <div>
        <FeedbackButton />
        <div
          className="pt-2 text-center text-xs opacity-30"
          title={import.meta.env.PUBLIC_GIT_TIMESTAMP}
        >
          v{import.meta.env.PUBLIC_VERSION}
        </div>
      </div>
    </nav>
  )
}
Navigation.displayName = 'Navigation'
