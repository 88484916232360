import { IconComponent } from '@/lib/types'

export const ForwardSlash: IconComponent = (properties) => {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 11 21"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M2.99976 20.5L10.4998 0H8.06779L0.499756 20.5H2.99976Z"
        fill="currentColor"
      />
    </svg>
  )
}
ForwardSlash.displayName = 'ForwardSlash'
