import { IconComponent } from '@/lib/types'

export const InterloomLogo: IconComponent = (properties) => (
  <svg
    fill="none"
    height="84"
    viewBox="0 0 84 84"
    width="84"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g fill="currentColor">
      <path d="M48.4134 69.681C46.4168 61.5244 43.4359 49.9601 43.4359 42C43.4359 34.0399 46.4168 22.4756 48.4134 14.319C49.0009 11.9187 49.611 9.42663 50.237 6.68418C50.4703 5.66245 51.5959 5.11784 52.5359 5.58056L59.205 8.86346C60.0833 9.29582 60.3819 10.4085 59.9402 11.2825C58.4 14.3296 57.1405 18.7332 55.9979 22.7277C54.9817 26.2803 54.0581 29.5094 53.1119 31.1731C51.4384 34.1156 51.0982 37.9142 50.8731 40.4281C50.8185 41.0378 50.7707 41.572 50.7123 42C50.7707 42.428 50.8185 42.9622 50.8731 43.5719C51.0982 46.0858 51.4384 49.8844 53.1119 52.8269C54.0581 54.4905 54.9817 57.7196 55.9979 61.2722C57.1404 65.2667 58.4 69.6704 59.9402 72.7175C60.3819 73.5915 60.0833 74.7042 59.205 75.1365L52.5359 78.4194C51.5959 78.8821 50.4703 78.3375 50.237 77.3158C49.6109 74.5733 49.0009 72.0813 48.4134 69.681Z" />
      <path d="M54.0879 42.1616C54.2259 45.4564 56.4445 48.8747 59.0903 51.6957C61.7919 54.5764 64.4236 55.8984 67.7499 57.5693C69.3931 58.3947 71.2057 59.3053 73.2801 60.531C73.9128 60.9049 74.7351 60.6961 75.096 60.0556L78.8442 53.403C79.1799 52.8071 78.9945 52.0518 78.398 51.7173C76.8376 50.8422 74.8728 49.9539 72.8649 49.0462C67.9899 46.8422 62.8611 44.5235 62.6492 42C62.8611 39.4765 67.9899 37.1578 72.8649 34.9538C74.8728 34.0461 76.8376 33.1578 78.398 32.2827C78.9945 31.9482 79.1799 31.1929 78.8442 30.597L75.096 23.9444C74.7351 23.3039 73.9128 23.0951 73.2801 23.469C71.2058 24.6947 69.3931 25.6053 67.7499 26.4307C64.4237 28.1016 61.7919 29.4236 59.0903 32.3043C56.4445 35.1253 54.2259 38.5436 54.0879 41.8384L54.0811 41.8384C54.0811 41.8922 54.0817 41.9461 54.0828 42C54.0817 42.0539 54.0811 42.1078 54.0811 42.1616L54.0879 42.1616Z" />
      <path d="M40.4962 42C40.4962 49.9601 37.5153 61.5244 35.5187 69.681C34.9312 72.0813 34.3211 74.5733 33.6951 77.3158C33.4618 78.3375 32.3362 78.8821 31.3962 78.4194L24.7271 75.1365C23.8487 74.7042 23.5502 73.5915 23.9919 72.7175C25.5321 69.6704 26.7916 65.2668 27.9342 61.2723C28.9504 57.7197 29.874 54.4905 30.8202 52.8269C32.4937 49.8844 32.8339 46.0858 33.0589 43.5719L33.059 43.5717C33.1136 42.9621 33.1614 42.428 33.2198 42C33.1614 41.572 33.1135 41.0378 33.0589 40.4281C32.8339 37.9142 32.4937 34.1156 30.8202 31.1731C29.874 29.5094 28.9504 26.2803 27.9342 22.7277C26.7916 18.7332 25.5321 14.3296 23.9919 11.2825C23.5502 10.4085 23.8488 9.29582 24.7271 8.86346L31.3962 5.58055C32.3362 5.11784 33.4618 5.66245 33.6951 6.68418C34.3211 9.42663 34.9311 11.9186 35.5187 14.3189C37.5153 22.4755 40.4962 34.0399 40.4962 42Z" />
      <path d="M29.8442 42.1616C29.7062 45.4564 27.4875 48.8747 24.8418 51.6957C22.1402 54.5764 19.5085 55.8984 16.1822 57.5693C14.539 58.3947 12.7264 59.3053 10.652 60.531C10.0193 60.9049 9.19696 60.6961 8.83612 60.0556L5.08792 53.403C4.75219 52.8071 4.93763 52.0518 5.53405 51.7173C7.09449 50.8422 9.05931 49.9539 11.0672 49.0462C15.9422 46.8422 21.071 44.5235 21.2829 42C21.071 39.4765 15.9422 37.1578 11.0672 34.9538C9.05931 34.046 7.09449 33.1578 5.53405 32.2827C4.93763 31.9482 4.75219 31.1929 5.08792 30.597L8.83613 23.9444C9.19697 23.3039 10.0193 23.0951 10.652 23.469C12.7263 24.6947 14.539 25.6053 16.1822 26.4307C19.5085 28.1016 22.1402 29.4236 24.8418 32.3042C27.4875 35.1253 29.7062 38.5436 29.8442 41.8384L29.851 41.8384C29.851 41.8922 29.8504 41.9461 29.8493 42C29.8504 42.0539 29.851 42.1078 29.851 42.1616L29.8442 42.1616Z" />
    </g>
  </svg>
)
InterloomLogo.displayName = 'InterloomLogo'
