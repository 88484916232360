import { IconComponent } from '@/lib/types'

export const TaskCompletedIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M12 0C10.6979 0 9.59896 0.838541 9.18229 2H5C3.89583 2 3 2.89583 3 4V18C3 19.1042 3.89583 20 5 20H19C20.1042 20 21 19.1042 21 18V4C21 2.89583 20.1042 2 19 2H14.8177C14.401 0.838541 13.3021 0 12 0ZM12 2C12.5521 2 13 2.44792 13 3C13 3.55208 12.5521 4 12 4H19V18H5V4H12C11.4479 4 11 3.55208 11 3C11 2.44792 11.4479 2 12 2ZM15.2917 7.29167L11 11.5885L8.70833 9.29167L7.29167 10.7083L11 14.4115L16.7083 8.70833L15.2917 7.29167Z"
        fill="currentColor"
      />
    </svg>
  )
}

TaskCompletedIcon.displayName = 'TaskCompletedIcon'
