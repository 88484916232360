import { IconComponent } from '@/lib/types'

export const TaskCircularCheckboxEmptyIcon: IconComponent = ({
  ...properties
}) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        clipRule="evenodd"
        d="M12.5 9C10.8442 9 9.5 10.3442 9.5 12C9.5 13.6558 10.8442 15 12.5 15C14.1558 15 15.5 13.6558 15.5 12C15.5 10.3442 14.1558 9 12.5 9ZM12.5 7C9.73958 7 7.5 9.23958 7.5 12C7.5 14.7604 9.73958 17 12.5 17C15.2604 17 17.5 14.7604 17.5 12C17.5 9.23958 15.2604 7 12.5 7Z"
        fill="currentColor"
        fillRule="evenodd"
        opacity="0.2"
      />
      <path
        clipRule="evenodd"
        d="M12.5 4C8.08374 4 4.5 7.58374 4.5 12C4.5 16.4163 8.08374 20 12.5 20C16.9163 20 20.5 16.4163 20.5 12C20.5 7.58374 16.9163 4 12.5 4ZM2.5 12C2.5 6.47917 6.97917 2 12.5 2C18.0208 2 22.5 6.47917 22.5 12C22.5 17.5208 18.0208 22 12.5 22C6.97917 22 2.5 17.5208 2.5 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

TaskCircularCheckboxEmptyIcon.displayName = 'TaskCircularCheckboxEmptyIcon'
